















import { Component, Prop, Vue } from 'vue-property-decorator';
import { lengthColors, PlaylistLength } from '../service/types';

@Component({
  components: {}
})
export default class LengthIcon extends Vue {
  @Prop({
    required: true
  })
  length!: PlaylistLength;

  @Prop({
    required: false,
    default: false
  })
  tooltip!: boolean;

  getLengthColor(length: PlaylistLength): string {
    return lengthColors[length];
  }
}
