var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height flex-nowrap pa-0 flex-column align-center justify-center ma-0",attrs:{"fluid":""}},[_c('Split',{attrs:{"single":!_vm.rightPanel || _vm.$vuetify.breakpoint.smAndDown,"right":_vm.rightPanel,"splitPercent":40},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('v-toolbar',{staticClass:"flex-grow-0 transparent",style:({
          width: '100%'
        }),attrs:{"color":"transparent","flat":""}},[(_vm.admin && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":2}},[(_vm.admin && _vm.users)?_c('v-select',{style:({
              'min-width': '5rem'
            }),attrs:{"value":_vm.selectedUserId,"items":_vm.users,"dense":"","hide-details":"","item-text":"email_address","item-value":"user_id","label":"User"},on:{"input":_vm.selectUser}}):_vm._e()],1):(_vm.admin)?_c('v-menu',{staticStyle:{"max-width":"600px"},attrs:{"bottom-right":"","max-height":"50vh"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}]),model:{value:(_vm.userMenuVisible),callback:function ($$v) {_vm.userMenuVisible=$$v},expression:"userMenuVisible"}},[_c('v-list',_vm._l((_vm.users),function(user){return _c('v-list-item',{key:user.user_id,on:{"click":function($event){return _vm.selectUser(user.user_id)}}},[_c('v-list-item-title',{class:{ 'primary--text': _vm.selectedUserId === user.user_id }},[_vm._v(_vm._s(user.email_address))])],1)}),1)],1):_vm._e(),_c('v-col',{staticClass:"pa-0",attrs:{"cols":!_vm.rightPanel && _vm.$vuetify.breakpoint.mdAndUp ? 2 : _vm.admin ? 3 : 4}},[_c('PlaylistAutocomplete',{attrs:{"playlists":_vm.editablePlaylists,"hide-details":"","solo":"","dense":"","loading":_vm.targetPlaylistLoading || _vm.playlistsLoading,"no-icons":true,"label":_vm.$t('app.playlists.edit.targetPlaylist')},model:{value:(_vm.targetPlaylistId),callback:function ($$v) {_vm.targetPlaylistId=$$v},expression:"targetPlaylistId"}})],1),(!_vm.targetPlaylistLoading && _vm.targetPlaylistId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"ml-3",style:({
                cursor: 'default'
              })},on),[_vm._v(" ("+_vm._s(_vm.totalDuration)+") ")])]}}],null,false,898102732)},[_vm._v(" "+_vm._s(_vm.$t('app.playlists.duration'))+" ")]):_vm._e(),_c('v-col',[_c('v-badge',{attrs:{"value":_vm.lastSavedBadge && _vm.lastSaved !== null,"color":"primary","content":((_vm.$t('app.playlists.edit.lastSaved')) + " " + (_vm.lastSaved ? _vm.lastSaved.toLocaleString() : '')),"left":"","overlap":"","transition":"slide-x-transition"}},[(
                _vm.targetPlaylist && !_vm.rightPanel && _vm.$vuetify.breakpoint.mdAndUp
              )?_c('v-btn',{staticClass:"cc-green-button",class:{ 'mr-3': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"loading":_vm.saveLoading,"disabled":_vm.targetPlaylistLoading,"dark":""},on:{"click":_vm.savePlaylist,"mouseenter":function($event){_vm.lastSavedBadge = true},"mouseleave":function($event){_vm.lastSavedBadge = false}}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$t('app.playlists.edit.save'))+" ")],1):(_vm.targetPlaylist)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({class:{ 'mr-3': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"loading":_vm.saveLoading,"icon":"","disabled":_vm.targetPlaylistLoading,"color":"green"},on:{"mouseenter":function($event){_vm.lastSavedBadge = true},"mouseleave":function($event){_vm.lastSavedBadge = false},"click":_vm.savePlaylist}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.playlists.edit.save'))+" ")]):_vm._e()],1),(
              _vm.targetPlaylist && !_vm.rightPanel && _vm.$vuetify.breakpoint.mdAndUp
            )?_c('v-btn',{attrs:{"loading":_vm.restoreLoading,"disabled":_vm.history.length === 0 || _vm.targetPlaylistLoading,"color":_vm.$vuetify.theme.dark ? 'cc-dark' : undefined,"dark":""},on:{"click":_vm.restorePlaylist}},[_c('v-icon',[_vm._v("mdi-restore")]),_vm._v(" "+_vm._s(_vm.$t('app.playlists.edit.restore'))+" ")],1):(_vm.targetPlaylist)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.restoreLoading,"icon":"","disabled":_vm.history.length === 0 || _vm.targetPlaylistLoading},on:{"click":_vm.restorePlaylist}},on),[_c('v-icon',[_vm._v("mdi-restore")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.playlists.edit.restore'))+" ")]):_vm._e(),(
              !_vm.rightPanel && _vm.targetPlaylist && _vm.$vuetify.breakpoint.mdAndUp
            )?_c('v-btn',{style:({
              float: 'right'
            }),attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleRightPanel(true)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-playlist-plus")]),_vm._v(" "+_vm._s(_vm.$t('app.playlists.edit.rightPanel'))+" ")],1):(!_vm.rightPanel && _vm.targetPlaylist)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.toggleRightPanel(true)}}},[_c('v-icon',[_vm._v("mdi-playlist-plus")])],1):_vm._e()],1)],1),_c('v-container',{staticClass:"fill-height flex-column flex-nowrap justify-center pa-4",attrs:{"fluid":""}},[(_vm.targetPlaylist)?_c('TracksTable',{staticClass:"cc-edit-target elevation-2",attrs:{"tracks":_vm.targetPlaylistTracks,"deleteButton":true,"dragHandle":true,"options":_vm.targetTableOptions,"loading":_vm.targetPlaylistLoading},on:{"loaded":_vm.targetTableLoaded,"delete":_vm.deleteTrack,"update:options":function($event){_vm.targetTableOptions=$event}}}):_c('div',{staticClass:"display-1",style:({
            userSelect: 'none',
            opacity: '0.2'
          })},[_vm._v(" "+_vm._s(_vm.$t('app.playlists.edit.notSelected'))+" ")])],1)]},proxy:true},{key:"right",fn:function(){return [_c('v-toolbar',{staticClass:"flex-grow-0",style:({
          width: '100%'
        }),attrs:{"color":"transparent","flat":""}},[(_vm.targetPlaylist && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":_vm.sourcePlaylistLoading || _vm.sourcePlaylistTracks.length === 0,"color":"primary"},on:{"click":_vm.addAllTracks}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-transfer-left")]),_vm._v(" "+_vm._s(_vm.$t('app.playlists.edit.addAllTracks'))+" ")],1):(_vm.targetPlaylist)?_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":_vm.sourcePlaylistLoading || _vm.sourcePlaylistTracks.length === 0,"color":"primary"},on:{"click":_vm.addAllTracks}},[_c('v-icon',[_vm._v("mdi-transfer-left")])],1):_vm._e(),_c('v-checkbox',{staticClass:"mr-4",attrs:{"loading":_vm.playlistsLoading,"dense":"","hide-details":"","label":_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.$t('app.playlists.edit.enableSourcePlaylist')
              : undefined},model:{value:(_vm.sourcePlaylistEnabled),callback:function ($$v) {_vm.sourcePlaylistEnabled=$$v},expression:"sourcePlaylistEnabled"}}),_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 4 : 5.5}},[_c('PlaylistAutocomplete',{attrs:{"hide-details":"","disabled":!_vm.sourcePlaylistEnabled,"playlists":_vm.sourcePlaylists,"loading":_vm.sourcePlaylistLoading || _vm.playlistsLoading,"label":_vm.$t('app.playlists.edit.sourcePlaylist')},model:{value:(_vm.sourcePlaylistId),callback:function ($$v) {_vm.sourcePlaylistId=$$v},expression:"sourcePlaylistId"}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"cc-green-button",on:{"click":function($event){return _vm.toggleRightPanel(false)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.$t('app.playlists.edit.closeRightPanel'))+" ")],1):_c('v-btn',{staticClass:"ml-4 cc-green-button",on:{"click":function($event){return _vm.toggleRightPanel(false)}}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1),_c('v-container',{staticClass:"fill-height flex-column flex-nowrap justify-center pa-4",attrs:{"fluid":""}},[(
            _vm.sourcePlaylistEnabled &&
              _vm.sourcePlaylistId &&
              _vm.sourcePlaylistId.length !== 0
          )?_c('TracksTable',{staticClass:"cc-edit-source elevation-2",attrs:{"addButton":true,"searchOptions":_vm.searchOptions,"tracks":_vm.sourcePlaylistTracks,"loading":_vm.sourcePlaylistLoading},on:{"update:tracks":_vm.sourceTracksUpdated,"loaded":_vm.sourceTableLoaded,"add":_vm.addTrack}}):_c('TracksTable',{staticClass:"cc-edit-source elevation-2",attrs:{"addButton":true,"searchOptions":_vm.searchOptions,"serverSide":true},on:{"update:tracks":_vm.sourceTracksUpdated,"loaded":_vm.sourceTableLoaded,"add":_vm.addTrack}})],1),_c('portal',{attrs:{"to":"rightDrawer"}},[_c('TracksFilters',{on:{"options":_vm.updateSearchOptions}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }