











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Playlist } from '@/service/types';

import ClassIcon from '@/components/ClassIcon.vue';
import LengthIcon from '@/components/LengthIcon.vue';

@Component({
  components: {
    ClassIcon,
    LengthIcon
  }
})
export default class PlaylistAutocomplete extends Vue {
  @Prop({ required: true })
  playlists!: Playlist[];

  @Prop({ default: false })
  noIcons!: boolean;
}
