















































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Playlist, ClassType, Track } from '@/service/types';
import {
  getPlaylists,
  getPlaylist,
  getTracks,
  updatePlaylist,
  User,
  getUsers
} from '@/service/api';
import { getModule } from 'vuex-module-decorators';
import { UserModule } from '@/store/modules/user';

import Split from '@/components/Split.vue';
import PlaylistAutocomplete from '@/components/PlaylistAutocomplete.vue';
import TracksTable, { SearchOptions } from '@/components/TracksTable.vue';
import TracksFilters from '@/components/drawer/TracksFilters.vue';
import { GuiModule } from '../../../store/modules/gui';
import Sortable, { utils } from 'sortablejs';
import { clone, formatSeconds } from '../../../util';
import { DataOptions } from 'vuetify';
import _debounce from 'lodash/debounce';
import { errorEventBus } from '../../../service';
import { EditorModule } from '../../../store/modules/editor';
import Axios, { CancelTokenSource } from 'axios';

@Component({
  name: 'Edit',
  components: {
    Split,
    PlaylistAutocomplete,
    TracksTable,
    TracksFilters
  }
})
export default class Edit extends Vue {
  playlistsLoading: boolean = false;
  playlists: Playlist[] | null = null;

  targetPlaylistLoading: boolean = false;

  targetTableOpts: Partial<DataOptions> | null = null;

  sourcePlaylistLoading: boolean = false;

  searchOptions: SearchOptions | null = null;

  sortableTarget: Sortable | null = null;
  sortableTargetDrop: Sortable | null = null;

  sortableSource: Sortable | null = null;
  sortableSourceDrop: Sortable | null = null;

  saveLoading: boolean = false;
  restoreLoading: boolean = false;

  lastSavedBadge: boolean = false;

  autoSaveToken: CancelTokenSource | null = null;
  autoSaveLoopTimeout: number | null = null;

  users: User[] = [];
  userMenuVisible: boolean = false;

  get history() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.history;
  }

  set history(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setHistory(val);
  }

  get lastSaved() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.lastSaved;
  }

  set lastSaved(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setLastSaved(val);
  }

  get rightPanel() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.rightPanel;
  }

  set rightPanel(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setRightPanel(val);
  }

  get targetPlaylistId() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.targetPlaylistId;
  }

  set targetPlaylistId(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setTargetPlaylistId(val);
  }

  get targetPlaylist() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.targetPlaylist;
  }

  set targetPlaylist(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setTargetPlaylist(val);
  }

  get targetPlaylistTracks() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.targetPlaylistTracks;
  }

  set targetPlaylistTracks(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setTargetPlaylistTracks(val);
  }

  get sourcePlaylistEnabled() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.sourcePlaylistEnabled;
  }

  set sourcePlaylistEnabled(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setSourcePlaylistEnabled(val);
  }

  get sourcePlaylistId() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.sourcePlaylistId;
  }

  set sourcePlaylistId(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setSourcePlaylistId(val);
  }

  get sourcePlaylist() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.sourcePlaylist;
  }

  set sourcePlaylist(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setSourcePlaylist(val);
  }

  get sourcePlaylistTracks() {
    const playlists = getModule(EditorModule, this.$store);

    return playlists.sourcePlaylistTracks;
  }

  set sourcePlaylistTracks(val) {
    const playlists = getModule(EditorModule, this.$store);

    playlists.setSourcePlaylistTracks(val);
  }

  get targetTableOptions() {
    return this.targetTableOpts;
  }

  set targetTableOptions(opts) {
    this.$set(this, 'targetTableOpts', opts);
  }

  get editablePlaylists() {
    return (
      this.playlists?.filter(
        p =>
          !p.deleted &&
          p.classTypes.indexOf(ClassType.Custom) !== -1 &&
          (!this.admin || p.userId === this.selectedUserId)
      ) ?? []
    );
  }

  get sourcePlaylists() {
    return this.playlists?.filter(p => p.id !== this.targetPlaylistId) ?? [];
  }

  get totalDuration(): string | undefined {
    return formatSeconds(
      this.targetPlaylistTracks.reduce(
        (total, t) => total + (t.duration ?? 0),
        0
      )
    );
  }

  get admin() {
    const userStore = getModule(UserModule, this.$store);

    return userStore.admin;
  }

  get selectedUserId() {
    const userStore = getModule(UserModule, this.$store);

    return userStore.selectedUserId;
  }

  @Watch('sourcePlaylistId')
  onSourcePlaylistChanged() {
    if (!this.sourcePlaylistId || this.sourcePlaylistId.length === 0) {
      return this.$set(this, 'sourcePlaylistTracks', []);
    }

    this.loadSourcePlaylist();
  }

  @Watch('targetPlaylistId')
  async onTargetPlaylistChanged() {
    if (this.targetPlaylistId.length === 0) {
      return this.$router.push({ name: 'editPlaylist' });
    }

    if (this.targetPlaylistId !== this.$route.params.playlistId) {
      this.$router.push({
        name: 'editPlaylist',
        params: {
          playlistId: this.targetPlaylistId
        }
      });
    }

    await this.loadTargetPlaylist();
  }

  makeHistory() {
    const MAX_HISTORY = 50;

    if (this.history.length > MAX_HISTORY) {
      this.history.shift();
    }

    this.history.push(clone(this.targetPlaylistTracks));
  }

  targetTableLoaded() {
    this.$nextTick(() => {
      this.makeTargetSortable();
    });
  }

  sourceTableLoaded() {
    this.$nextTick(() => {
      this.makeSourceSortable();
    });
  }

  sourceTracksUpdated(tracks: Track[]) {
    this.$set(this, 'sourcePlaylistTracks', tracks);
  }

  addTrack(index: number) {
    this.makeHistory();

    this.targetPlaylistTracks.push(clone(this.sourcePlaylistTracks[index]));
  }

  deleteTrack(index: number) {
    console.log('deleted', index);
    this.makeHistory();
    this.targetPlaylistTracks.splice(this.targetIndex(index), 1);
    this.refreshTargetTable();
  }

  makeTargetSortable() {
    try {
      this.sortableTarget?.destroy();
    } catch (e) {
      // This can fail if vue already removed the element
    }

    try {
      this.sortableTargetDrop?.destroy();
    } catch (e) {
      // This can fail if vue already removed the element
    }

    this.sortableTarget = new Sortable(
      document.querySelector('.cc-edit-target tbody') as HTMLElement,
      {
        group: 'cc-edit',
        animation: 150,
        handle: this.$vuetify.breakpoint.smAndDown ? '.drag-handle' : undefined,
        swapThreshold: 0.3,
        revertOnSpill: false,
        onUpdate: ({ oldIndex, newIndex, item, to }) => {
          this.makeHistory();

          const track = this.targetPlaylistTracks[this.targetIndex(oldIndex!)];
          this.targetPlaylistTracks.splice(this.targetIndex(oldIndex!), 1);

          this.targetPlaylistTracks.splice(
            this.targetIndex(newIndex!),
            0,
            clone(track)
          );
          this.refreshTargetTable();
        }
        // TODO maybe reenable this?
        // onSpill: ({ oldIndex }) => {
        //   this.deleteTrack(oldIndex!);
        // }
      }
    );

    if (this.$vuetify.breakpoint.smAndDown) {
      return;
    }

    this.sortableTargetDrop = new Sortable(
      document.querySelector('.cc-edit-target') as HTMLElement,
      {
        group: {
          name: 'cc-drop',
          pull: false
        },
        ghostClass: 'cc-drop-empty',
        sort: false,
        onAdd: ({ oldIndex, newIndex, to, item }) => {
          this.addTrack(oldIndex!);

          // Make sure the dropped in elements won't stay there
          const table = document.querySelector('.cc-edit-target')!;
          const children = table.querySelectorAll('.cc-edit-target > tr');
          for (const child of children) {
            table.removeChild(child);
          }

          this.targetGoToLastPage();
        }
      }
    );
  }

  refreshTargetTable() {
    const tracks = this.targetPlaylistTracks;

    const page = this.targetTableOptions?.page ?? 1;

    this.$set(this, 'targetPlaylistTracks', []);
    this.$nextTick(() => {
      this.$set(this, 'targetPlaylistTracks', tracks);
    });

    // WARNING: A workaround for vuetify's table bug.
    // It won't stay on the same page otherwise.
    if (this.targetTableOptions) {
      this.targetTableOptions.page = Math.max(page - 1, 1);
    }
    this.$nextTick(() => {
      if (this.targetTableOptions) {
        this.targetTableOptions.page = page;
      }
    });
  }

  targetIndex(idx: number) {
    if (
      !this.targetTableOptions?.page ||
      !this.targetTableOptions?.itemsPerPage
    ) {
      return idx;
    }

    return (
      idx +
      (this.targetTableOptions.page - 1) * this.targetTableOptions.itemsPerPage
    );
  }

  targetGoToLastPage() {
    if (!this.targetTableOptions?.itemsPerPage) {
      return;
    }

    this.targetTableOptions.page =
      Math.floor(
        this.targetPlaylistTracks.length / this.targetTableOptions.itemsPerPage
      ) + 1;
  }

  makeSourceSortable() {
    try {
      this.sortableSource?.destroy();
    } catch (e) {
      // This can fail if vue already removed the element
    }

    try {
      this.sortableSourceDrop?.destroy();
    } catch (e) {
      // This can fail if vue already removed the element
    }

    if (this.$vuetify.breakpoint.smAndDown) {
      return;
    }

    this.sortableSourceDrop = new Sortable(
      document.querySelector('.cc-edit-source tbody') as HTMLElement,
      {
        group: {
          name: 'cc-drop',
          pull: 'clone',
          put: false
        },
        animation: 150,
        filter: '.v-treeview-node__toggle',
        sort: false
      }
    );

    this.sortableSource = new Sortable(
      document.querySelector('.cc-edit-source tbody') as HTMLElement,
      {
        group: {
          name: 'cc-edit',
          pull: 'clone',
          put: false
        },
        animation: 150,
        filter: '.v-treeview-node__toggle',
        sort: false
      }
    );
  }

  updateSearchOptions(options: SearchOptions) {
    this.$set(this, 'searchOptions', options);
  }

  toggleRightPanel(on: boolean) {
    this.rightPanel = on;

    const gui = getModule(GuiModule, this.$store);

    gui.setRightDrawerEnabled(on);
  }

  addAllTracks() {
    this.makeHistory();

    for (const track of this.sourcePlaylistTracks) {
      this.targetPlaylistTracks.push(clone(track));
    }

    this.refreshTargetTable();
  }

  parseParams() {
    if (this.$route.params.playlistId) {
      if (this.playlists && !this.targetPlaylist) {
        this.targetPlaylistId = this.$route.params.playlistId;
      }
    }
  }

  async selectUser(userId?: string) {
    if (userId) {
      const userStore = getModule(UserModule, this.$store);

      userStore.setSelectedUserId(userId ?? null);

      await this.getPlaylists();

      const playlists = getModule(EditorModule, this.$store);

      // TODO this is ugly af
      playlists.setTargetPlaylistId('');
      playlists.setHistory([]);
      playlists.setLastSaved(null);
      playlists.setTargetPlaylist(null);
      playlists.setTargetPlaylistTracks([]);

      this.$router.push({ name: 'editPlaylist' });
    }
  }

  async getUsers() {
    if (!this.admin) {
      return;
    }

    this.$set(this, 'users', await getUsers());
  }

  async autoSave() {
    // TODO(autosave)
    if (!process.env.VUE_APP_PLAYLIST_AUTOSAVE) {
      return;
    }

    if (!this.targetPlaylist || this.saveLoading || this.history.length === 0) {
      return;
    }

    try {
      if (this.autoSaveToken) {
        this.autoSaveToken.cancel();
        this.autoSaveToken = null;
      }

      this.autoSaveToken = Axios.CancelToken.source();
      const p = clone(this.targetPlaylist);
      p.tracks = this.targetPlaylistTracks.map(t => t.id);
      await updatePlaylist(p, this.autoSaveToken.token);
      this.lastSaved = new Date();
      this.autoSaveToken = null;
    } catch (_) {
      // We don't care if autosave fails.
      this.autoSaveToken = null;
    }
  }

  async savePlaylist() {
    if (!this.targetPlaylist) {
      return;
    }

    this.saveLoading = true;
    try {
      const p = clone(this.targetPlaylist);
      p.tracks = this.targetPlaylistTracks.map(t => t.id);
      await updatePlaylist(p);
      this.lastSaved = new Date();
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.saveLoading = false;
    }
  }

  async restorePlaylist() {
    if (this.history.length === 0 || !this.targetPlaylist) {
      return;
    }

    this.restoreLoading = true;

    try {
      this.$set(this, 'targetPlaylistTracks', this.history.pop());
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.restoreLoading = false;
    }
  }

  async loadSourcePlaylist() {
    if (!this.sourcePlaylistId || this.sourcePlaylistId.length === 0) {
      return;
    }

    this.sourcePlaylistLoading = true;
    try {
      const playlist = await getPlaylist(this.sourcePlaylistId);
      this.sourcePlaylistTracks = await getTracks(...playlist.tracks);
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.sourcePlaylistLoading = false;
    }
  }

  async loadTargetPlaylist() {
    this.targetPlaylist = null;
    if (this.targetPlaylistId.length === 0) {
      return;
    }

    this.targetPlaylistLoading = true;
    try {
      const p = await getPlaylist(this.targetPlaylistId);
      this.targetPlaylist = p;
      const tracks = await getTracks(...p.tracks);
      this.targetPlaylistTracks = tracks;
      this.history = [];
      this.lastSaved = null;
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.targetPlaylistLoading = false;
    }
  }

  async getPlaylists() {
    this.playlistsLoading = true;
    try {
      this.playlists = await getPlaylists(this.selectedUserId ?? undefined);
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.playlistsLoading = false;
    }
  }

  autoSaveLoop() {
    const AUTO_SAVE_INTERVAL_MS = 1000 * 30 * 1;

    if (this.autoSaveLoopTimeout !== null) {
      clearTimeout(this.autoSaveLoopTimeout);
      this.autoSaveLoopTimeout = null;
    }

    this.autoSaveLoopTimeout = setTimeout(async () => {
      this.autoSave(); // not awaiting on purpose, if it is too slow, it is cancelled.
      this.autoSaveLoop();
    }, AUTO_SAVE_INTERVAL_MS) as any;
  }

  created() {
    this.updateSearchOptions = _debounce(this.updateSearchOptions, 1000, {
      leading: false,
      trailing: true
    });
  }

  async mounted() {
    await this.getUsers();
    await this.getPlaylists();
    this.parseParams();
    this.autoSaveLoop();
  }

  beforeDestroy() {
    const gui = getModule(GuiModule, this.$store);

    gui.setRightDrawerEnabled(false);

    if (this.autoSaveLoopTimeout !== null) {
      clearTimeout(this.autoSaveLoopTimeout);
    }

    this.autoSave();
  }
}
