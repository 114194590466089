





































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SearchOptions } from '../TracksTable.vue';
import { Filter } from '../../service/api';
import { Mood, Genre, Meta, EnergyLevel } from '../../service/types';
import { getMeta, allowedGenres } from '../../service';

@Component
export default class TracksFilters extends Vue {
  genericFilter: string = '';

  bpmRange: number[] = [0, 250];
  bpmEnabled: boolean = false;

  genres: string[] = [];
  moods: string[] = [];
  energyLevels: string[] = [];

  meta: Meta | null = null;

  filters: {
    name: string;
    enabled: boolean;
    value: string;
  }[] = [
    {
      name: 'artist',
      enabled: false,
      value: ''
    },
    {
      name: 'title',
      enabled: false,
      value: ''
    }
  ];

  get mainGenres(): Genre[] {
    if (!this.meta) {
      return [];
    }

    return this.meta.genres
      .sort((g1, g2) =>
        g1.name.toLowerCase().localeCompare(g2.name.toLowerCase())
      )
      .filter(g => allowedGenres.find(a => a === g.name.toLowerCase()));
  }

  get allGenres(): Genre[] {
    if (!this.meta) {
      return [];
    }

    return this.meta.genres
      .map<Genre[]>(g => {
        if (g.subgenres) {
          return g.subgenres;
        }
        return [g];
      })
      .reduce<Genre[]>((all, genres) => {
        return [...all, ...genres];
      }, [])
      .sort((g1, g2) =>
        g1.name.toLowerCase().localeCompare(g2.name.toLowerCase())
      );
  }

  get allMoods(): Mood[] {
    if (!this.meta) {
      return [];
    }

    return this.meta.moods;
  }

  get allEnergyLevels(): EnergyLevel[] {
    if (!this.meta) {
      return [];
    }

    return this.meta.energyLevels;
  }

  get allFiltersEnabledValue() {
    if (this.allFiltersMixed) {
      return;
    }

    for (const filter of this.filters) {
      return filter.enabled;
    }
  }

  get allFiltersMixed() {
    let val: boolean | undefined;
    for (const filter of this.filters) {
      if (val === undefined) {
        val = filter.enabled;
        continue;
      }

      if (filter.enabled !== val) {
        return true;
      }
    }

    return false;
  }

  get allFiltersDisabled() {
    for (const filter of this.filters) {
      if (filter.value.length > 0) {
        return false;
      }
    }
    return true;
  }

  get searchOptions(): SearchOptions {
    const filters = this.filters
      .filter(f => f.enabled && f.value.length > 0)
      .map<Filter>(f => {
        return {
          filter: f.name,
          values: [`%${f.value.trim()}%`]
        };
      });

    if (this.genericFilter.length > 0) {
      filters.push({
        filter: 'generic',
        values: [`%${this.genericFilter.trim()}%`]
      });
    }

    if (this.genres.length > 0) {
      filters.push({
        filter: 'genre',
        values: this.genres
      });
    }

    if (this.moods.length > 0) {
      filters.push({
        filter: 'mood',
        values: this.moods
      });
    }

    if (this.energyLevels.length > 0) {
      filters.push({
        filter: 'energy_level',
        values: this.energyLevels
      });
    }

    return {
      filters,
      bpm: this.bpmEnabled
        ? {
            min: this.bpmRange[0],
            max: this.bpmRange[1]
          }
        : undefined
    };
  }

  removeGenre(genre: Genre) {
    const index = this.genres.findIndex(g => g === genre.name);

    if (index >= 0) {
      this.genres.splice(index, 1);
    }
  }

  removeMood(mood: Mood) {
    const index = this.moods.findIndex(m => m === mood.name);

    if (index >= 0) {
      this.moods.splice(index, 1);
    }
  }

  removeEnergyLevel(energyLevel: EnergyLevel) {
    const index = this.energyLevels.findIndex(e => e === energyLevel.name);

    if (index >= 0) {
      this.energyLevels.splice(index, 1);
    }
  }

  @Watch('searchOptions', { deep: true })
  onOptsChanged() {
    this.$emit('options', this.searchOptions);
  }

  toggleAllFilters() {
    let val = false;

    if (this.allFiltersEnabledValue !== undefined) {
      val = !this.allFiltersEnabledValue;
    }

    if (this.allFiltersMixed) {
      val = false;
    }

    for (const filter of this.filters) {
      filter.enabled = val;
    }
  }

  async created() {
    this.meta = await getMeta();
  }
}
