




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClassType, classColors } from '../service/types';

import FlexSVG from '!vue-svg-loader!@/assets/class_icons/flex.svg';
import CircuitsSVG from '!vue-svg-loader!@/assets/class_icons/circuits.svg';
import HighEnergySVG from '!vue-svg-loader!@/assets/class_icons/high_energy.svg';
import LiftSVG from '!vue-svg-loader!@/assets/class_icons/lift.svg';
import SpinSVG from '!vue-svg-loader!@/assets/class_icons/spin.svg';
import ToneSVG from '!vue-svg-loader!@/assets/class_icons/tone.svg';

@Component({
  components: {
    FlexSVG,
    CircuitsSVG,
    HighEnergySVG,
    LiftSVG,
    SpinSVG,
    ToneSVG
  }
})
export default class ClassIcon extends Vue {
  @Prop({
    required: true
  })
  classType!: ClassType;

  @Prop({
    required: false,
    default: false
  })
  tooltip!: boolean;

  getPrimaryClassColor(classType: ClassType): string {
    return classColors[classType].primary;
  }
}
