












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import SplitJS from 'split.js';

@Component
export default class Split extends Vue {
  @Prop({
    type: Number,
    default: 50
  })
  splitPercent!: number;

  @Prop({
    type: Boolean,
    default: false
  })
  single!: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  right!: boolean;

  renderSingle: boolean = true;

  splitInstance: SplitJS.Instance | null = null;

  @Watch('single')
  onSingleChanged() {
    this.split();
  }

  split() {
    if (!this.renderSingle) {
      this.splitInstance?.destroy();
    }

    if (this.single) {
      this.renderSingle = true;
      return;
    }

    this.renderSingle = false;

    this.$nextTick(() => {
      this.splitInstance = SplitJS(
        [this.$refs.left as HTMLElement, this.$refs.right as HTMLElement],
        {
          gutterSize: 15,
          snapOffset: 0,
          sizes: [this.splitPercent, 100 - this.splitPercent],
          elementStyle: (dimension, size, gutterSize) => ({
            'flex-basis': `calc(${size}% - ${gutterSize}px)`
          }),
          gutterStyle: (dimension, gutterSize) => ({
            'flex-basis': `${gutterSize}px`
          })
        }
      );
    });
  }

  mounted() {
    this.split();
  }

  beforeDestroy() {
    if (!this.renderSingle) {
      this.splitInstance?.destroy();
    }
  }
}
